import React from 'react'

export default function CompetitionTerms() {
  return (
    <div className='terms'>
        <h1 style={{fontWeight: 900}}>Competition Terms & Conditions</h1>

        <p className='indented'><b>1. Eligibility: </b>Open to UK residents aged 18 and over who are planning a wedding.</p>
        <p className='indented'><b>2. Entry: </b>Participants must submit an enquiry via the First Dance Music website.</p>
        <p className='indented'><b>3. Prize: </b>One winner will receive a live performance of their chosen aisle walk song by Doug, performed at their wedding.</p>
        <p className='indented'><b>4. Competition Period: </b>The competition runs monthly, with the winner drawn on the last day of each month.</p>
        <p className='indented'><b>5. Performance Timeframe: </b>The prize must be redeemed within 18 months of winning.</p>
        <p className='indented'><b>6. Location: </b>The location of the wedding must be within the mainland of the UK.</p>
        <p className='indented'><b>7. Affiliation: </b>This competition is in no way sponsored, endorsed, or administered by Meta.</p>
        <p className='indented'><b>8. Non-Transferable: </b>The prize cannot be exchanged for cash or transferred to another party.</p>
        <p className='indented'><b>9. Contact: </b>The winner will be notified via email within 7 days of the draw.</p>

    </div>
  )
}
