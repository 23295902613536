import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Context/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import close from '../../../Assets/Icons/close.png'
import { Locations, Timings, WeddingSchedule, UnspecifiedScheduleId } from '../../../Constants';
import moment from 'moment'
import { SplitName } from '../../../Utilities.js'
import { useNavigate } from 'react-router-dom'

export default function ModalEnquire( { setOpen, act } ) {

  let { currentUser } = useAuth()
  let navigate = useNavigate()
      // modal state
  const [name, setName ] = useState("")
  const [email, setEmail ] = useState("")
  const [number, setNumber ] = useState("")
  const [date, setDate ] = useState("")
  const [startTime, setStartTime ] = useState("")
  const [endTime, setEndTime ] = useState("")
  const [venue, setVenue ] = useState("")
  const [location, setLocation ] = useState("")
  const [notes, setNotes ] = useState("")
  const [loading, setLoading ] = useState(false)
  const [sent, setSent ] = useState(false)
  const [performanceSlot, setPerformanceSlot ] = useState("")


  useEffect(() => {
    if (currentUser?.email) {
      setName(currentUser.displayName);
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

 // create client account, save the quote, inform artists
 const handleEnquire = () => {

    if (moment(date).isBefore(moment())) {
      toast.error("Please add a date in the future")
    } else if (!name || !email || !venue || !location) { // performanceSlot is optional
      toast.error("Please add all required fields")
    } else if (!number) {
      toast.error("Please provide your mobile number so we can readily send you quotes via WhatsApp")
    } else if (!date) {
      toast.error("Please ensure date has been correctly completed")
    } else if (!endTime || !startTime) {
      toast.error("Please ensure start and end times are correctly completed")
    } else if (!notes) {
      toast.error("Please add a brief note. This helps the musician provide a more accurate quote.")
    } else {
      setLoading(true)

      const { firstName, lastName } = SplitName(name);

      axios.post(`${process.env.REACT_APP_API}/users/create-new-client`, {
        firstName,
        lastName,
        email,
        number,
        img:"https://caretestresources.s3.eu-west-2.amazonaws.com/avatar.png",
        date,
        actType: act.category,
        location,
      })
      .then((userResponse) => {

        // check that date is not more than two years in the future
        if (moment(date).isAfter(moment().add(2, 'years'))) {
          setLoading(false)

          window.alert(
            "Unfortunately, bookings cannot be more than 2 years in the future.\n\n" +
            "Please use the chat button to contact support and discuss your options."
          )

          return
        }

        // check if there is a performance slot allocated and find the corresponding wedding schedule item
        const matchingSlot = WeddingSchedule.find(slot => slot.name === performanceSlot);

        let requestPayload = {
          title: "Request",
          date: moment(date).format("YYYY-MM-DD"),
          name,
          email,
          clientId: userResponse.data.clientId,
          info: {actType: act.category, performanceSlot, startTime, endTime, venue, location},
          openRequest: false,
          artistId: act.artistId,
          actName: act._id,
          notes, backgroundColor:'#ecbdba',
          borderColor:'#ecbdba',
          textColor:'#fff',
          weddingSchedule: matchingSlot || WeddingSchedule[UnspecifiedScheduleId]
        }

        // add meta's data for server
        const fbpCookie = document.cookie.split('; ').find(row => row.startsWith('_fbp='));
        const fbp = fbpCookie ? fbpCookie.split('=')[1] : null;
        const fbcCookie = document.cookie.split('; ').find(row => row.startsWith('_fbc='));
        const fbc = fbcCookie ? fbcCookie.split('=')[1] : null;

        const eventData = {
          eventName: 'Lead',
          userData: {
            email: email,
            phone: '',      // TODO number is now captured, if we ever need to it can be added here
            fbp,            // Pixel ID
            fbc,            // Click ID
            firstName,
            lastName
          }
        }

        const customData = {
          leadType: 'Request'
        }

        axios.post(`${process.env.REACT_APP_API}/requests/create-new-request`, { requestPayload, eventData, customData })
        .then(() => {
          if (userResponse.status === 201) {
            //toast.success("Successfully sent request and created account")
            navigate('/success')
          } else { // should be 200
            //toast.success("Successfully sent request")
            navigate('/success')
          }

          setOpen(false)
          setSent(true)
          setLoading(false)
          setName("");setDate("");setEmail("");setNotes("");setPerformanceSlot("");
          setStartTime("");setVenue("");setLocation("");setEndTime("");setNumber("");
        })
        .catch((error) => {
          // check special backend codes
          if (
            (error?.response?.status === 409) &&
            (error?.response?.data?.subCode === 'DIFFERENT_DATE')
          ) {
              window.alert(
              `${error.response.data.message}\n\n` +
              "Please use the chat button to contact support and discuss your options."
            )
          } else {
            toast.error("Error submitting request")
          }

          setLoading(false)
        })
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          // display a specific server error message
          toast.error(error.response.data.message);
        } else {
          // handle other errors
          toast.error("Error submitting request");
        }

        setLoading(false)
      })
    }
  }


  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

      <div className='modal-container' onClick={handleContainerClick}>
        <img className='hover close-modal-btn' src={close} alt='Close First Dance Music enquire box' onClick={() => setOpen(false)}/>

        <h3 className='modal-header'>Request Quote</h3>

          <label style={{marginBottom: 5}}>Wedding date</label>
          <input
              className='single-input'
              style={{ color: !date ? '#B5B5B5' : null }}
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
          />

          <div className='vertical-spacer' />

          <input
              className='top-input'
              type='text'
              placeholder='Full name'
              value={name}
              disabled={currentUser}
              onChange={(e) => setName(e.target.value) }
          />
          <input
              className='middle-input'
              type='text'
              placeholder='Email address'
              value={email}
              disabled={currentUser}
              onChange={(e)=>setEmail(e.target.value)}
          />
          <input
              className='bottom-input'
              type='text'
              placeholder='Mobile Number'
              value={number}
              disabled={currentUser}
              onChange={(e)=>setNumber(e.target.value)}
          />

          <div className='vertical-spacer' />

          <div style={{display:'flex', justifyContent:'space-between'}}>
              <label>Music to Start (estimate)</label>
              <label style={{marginLeft: 10}}>Music to End (estimate)</label>
          </div>

          <div style={{width: '100%', display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
              <input
                className='left-input'
                style={{ color: !startTime ? '#B5B5B5' : null }}
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />

              <input
                className='right-input'
                style={{ color: !startTime ? '#B5B5B5' : null }}
                type="time"
                value={endTime}
                placeholder="hh:mm"
                onChange={(e) => setEndTime(e.target.value)}
              />
          </div>

          <div className='vertical-spacer' />

          <input
              className='top-input'
              type="text"
              value={venue}
              onChange={(e)=>setVenue(e.target.value)}
              placeholder='Venue name'
          />

          <select
              className='middle-input'
              value={location}
              onChange={(e) => setLocation(e.target.value)}
          >
              <option value="">
                  <em>Select location</em>
              </option>
              {Locations.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                  ))
              }
          </select>

          <select
              className='bottom-input'
              value={performanceSlot}
              onChange={(e) => setPerformanceSlot(e.target.value)}
          >
              <option value="">
                  Select performance slot (optional)
              </option>
              {Timings.map((type, i) => (
                  <option key={i} value={type.name}>
                      {type.name}
                  </option>
                ))
              }
          </select>

          <div className='vertical-spacer' />

          <textarea
              style={{resize: 'none', height: 200, padding: 10}}
              className='single-input'
              placeholder={`Notes e.g. drinks reception, first dance, start times etc. \nThis helps musicians tailor their quote to your needs.`}
              value={notes}
              onChange={(e)=>setNotes(e.target.value)}
          />

          <div className='vertical-spacer' />

          <button className='modal-btn' onClick={handleEnquire} disabled={loading}>
            {loading ? 'Requesting...' : "Request quote"}
          </button>

        </div>
    </div>
  )
}
