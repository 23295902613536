import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import { Chart } from 'chart.js/auto';  // Import the Chart class
//import 'chart.js/auto';  // Import the Chart class

export default function ClientList() {

    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    const getClients = () => {
        axios.get(`${process.env.REACT_APP_API}/users/get-clients`)
        .then((res)=>{
            setClients(res.data)
        })
        .catch((error) => console.log(error))
    }

    function formatDate(date) {
        if (!date) {
            return 'Unknown'; // Handles cases where the date is null or undefined
        }

        if (date === 'Never') {
            return 'Never'; // Handles explicit 'Never' string
        }

        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-GB', options); // Adjust locale if needed
    }

    const totalClients = clients.length
    const validLoginsCount = clients.filter(client =>
        client.lastSignInTime &&
        client.lastSignInTime !== 'Unknown' &&
        client.lastSignInTime !== 'Never'
    ).length
    const validLoginsPercentage = ((validLoginsCount / totalClients) * 100).toFixed(2)

    return (
        <div style={{textAlign:'center', backgroundColor:'#f8f9fb', height: '100vh', overflowX:'auto'}}>
            <div style={{ margin: '1rem' }}>
                <h2>Number of Clients: {totalClients}</h2>
                <h3>
                    Clients that have logged in: {validLoginsCount} ({validLoginsPercentage}%)
                </h3>
            </div>

            <hr style={{ width: '50%', margin: '2rem auto' }} />

            <ClientSignUpChart clients={clients}/>

            <hr style={{ width: '50%', margin: '2rem auto' }} />

            <table id='artist-list-table' style={{width: 800, maxWidth:'90vw', margin: '1rem auto'}}>
                <thead>
                    <tr style={{border:'1px solid grey'}}>
                        <th></th>
                        <th><strong>Name</strong></th>
                        <th><strong>Email</strong></th>
                        <th><strong>Client Since</strong></th>
                        <th><strong>Last Logged In</strong></th>
                    </tr>
                </thead>
                <tbody>
                    {clients.map((client, i) => {
                        return <tr key={i}>
                            <td>
                                <img
                                    style={{height: 40, borderRadius:'50%', objectFit:'cover', objectPosition:'center'}}
                                    src={client.img}
                                    alt={client.firstName}
                                />
                            </td>
                            <td>{client.firstName} {client.lastName}</td>
                            <td>{client.email}</td>
                            <td>{formatDate(client.createdAt)}</td>
                            <td>{formatDate(client.lastSignInTime)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}


const ClientSignUpChart = ({ clients }) => {
    const chartRef = useRef(null)

    useEffect(() => {
        // Destroy the previous chart instance if it exists
        if (chartRef.current) {
            chartRef.current.destroy()
        }

        // Get the canvas element
        const canvas = document.getElementById('clientSignUpChart');

        // Manipulate the data to get counts for each day
        const userCountsPerDay = {}
        const rollingPercentages = []

        clients.forEach(client => {
            const signupDate = new Date(client.createdAt)
            const formattedDate = signupDate.toLocaleDateString('en-GB') // Ensure 'dd/mm/yyyy' format
            userCountsPerDay[formattedDate] = (userCountsPerDay[formattedDate] || 0) + 1
        })

        // Helper function to parse 'dd/mm/yyyy' correctly
        const parseDate = (dateString) => {
            const [day, month, year] = dateString.split('/').map(Number)
            return new Date(year, month - 1, day) // Note: month is 0-indexed in JS
        }

        // Calculate rolling 30-day login percentage
        const sortedDates = Object.keys(userCountsPerDay).sort((a, b) => parseDate(a) - parseDate(b))

        sortedDates.forEach((currentDate, index) => {
            const endDate = parseDate(currentDate)
            const startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 29)

            const recentClients = clients.filter(client => {
                const signupDate = new Date(client.createdAt)
                return signupDate >= startDate && signupDate <= endDate
            })

            const recentLogins = recentClients.filter(client =>
                client.lastSignInTime && client.lastSignInTime !== 'Unknown' && client.lastSignInTime !== 'Never'
            )

            const loginPercentage = recentClients.length > 0
                ? ((recentLogins.length / recentClients.length) * 100).toFixed(2)
                : 0

            rollingPercentages.push(loginPercentage)
        })

        // Convert the data for the chart
        const chartData = {
            labels: sortedDates,
            datasets: [
                {
                    label: 'Clients Signed Up',
                    data: sortedDates.map(date => userCountsPerDay[date]),
                    fill: false,
                    borderColor: 'rgba(75,192,192,1)',
                    yAxisID: 'y-signups',
                },
                {
                    label: 'Rolling 30-Day Login Percentage',
                    data: rollingPercentages,
                    fill: false,
                    borderColor: 'rgba(255,99,132,1)',
                    yAxisID: 'y-percentage',
                },
            ],
        }

        // Create a new chart instance
        chartRef.current = new Chart(canvas, {
            type: 'line',
            data: chartData,
            options: {
                scales: {
                    'y-signups': {
                        type: 'linear',
                        position: 'left',
                        title: {
                            display: true,
                            text: 'Signups',
                        },
                    },
                    'y-percentage': {
                        type: 'linear',
                        position: 'right',
                        title: {
                            display: true,
                            text: 'Login Percentage',
                        },
                        ticks: {
                            callback: value => `${value}%`, // Format as percentage
                        },
                    },
                },
            },
        })

        // Cleanup function to destroy the chart when the component is unmounted
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy()
            }
        };
    }, [clients])

    return <canvas id="clientSignUpChart" />
}

