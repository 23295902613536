import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import axios from 'axios'
import Badge from '@mui/material/Badge'
import { UseInbox } from '../../../Context/InboxContext'
import './AdminDashboard.css'

export default function AdminDashboard() {

    let { unreadSupportChats, allUnreadChats } = UseInbox()
    const [dryRun, setDryRun] = useState(true)
    const [migrationStatus, setMigrationStatus] = useState('')

    const handleMigration = () => {
        setMigrationStatus('Starting migration...')

        axios.post(`${process.env.REACT_APP_API}/admin/act-type-migration`, { dryRun })
            .then((res) => {
                if (dryRun) {
                    console.log('Dry-run results:', res.data)
                    setMigrationStatus('Dry-run complete. Please check console and server for details.')
                } else {
                    console.log('Migration summary:', res.data)
                    setMigrationStatus(`Migration successful. Please check console for details.`);
                }
            })
            .catch((error) => {
                console.error('Error during migration:', error);
                setMigrationStatus('Migration failed. Please check the console for details.')
            })
    }

    return (
        <div className='business-outlet'>
            <div id='admin-nav'>
                <Link className='admin-nav-link' to='/artists/admin/artist-list'>
                    Artists
                </Link>
                <Link className='admin-nav-link' to='/artists/admin/client-list'>
                    Clients
                </Link>
                <Link className='admin-nav-link' to='/artists/admin/act-list'>
                    Admin Acts
                </Link>
                <Link className='admin-nav-link' to='/artists/admin/bookings'>
                    Bookings
                </Link>
                <Link className='admin-nav-link' to='/artists/admin/all-requests'>
                    All Requests
                </Link>
                <Link className='admin-nav-link' to='/artists/admin/message'>
                    Message
                </Link>
                <Badge badgeContent={unreadSupportChats?.length} color="error">
                    <Link className='admin-nav-link' to='/artists/admin/support-inbox'>
                        Support
                    </Link>
                </Badge>
                <Badge badgeContent={allUnreadChats?.length} max={999} color="error">
                    <Link className='admin-nav-link' to='/artists/admin/all-inbox'>
                        All Chats
                    </Link>
                </Badge>
                <Link className='admin-nav-link' to='/artists/admin/system'>
                    System
                </Link>
            </div>

            {/* below code was used for admin to initiate act type migration - keeping,  but hidden, for future reference/use */}
            <div style={{ margin: '40px', visibility: 'hidden' /*'visible' */}}>
                {/* Migration Button and Status Message */}
                <div>
                    <button
                        onClick={handleMigration}
                        disabled={false}
                    >
                        {dryRun ? 'Dry-run Migration' : 'Perform Act Types Migration'}
                    </button>
                </div>
                <label>
                    <input
                        type="checkbox"
                        checked={dryRun}
                        onChange={() => setDryRun(!dryRun)}
                    />
                    Enable Dry-Run
                </label>
                <p>{migrationStatus}</p>
            </div>

            <Outlet />
        </div>
    )
}
