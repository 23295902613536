import React, { useEffect, useState, useRef } from 'react'
import './ArtistProfile.css'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ExtractEmbedFromVideoUrl } from '../../../Utilities.js'
import locationIcon from '../../../Assets/location.png'
import noteIcon from '../../../Assets/note.png'
import YouTube from 'react-youtube';
import likeIcon from '../../../Assets/Icons/like.png'
import likedIcon from '../../../Assets/Icons/liked.png'
import ModalShare from '../Modals/ModalShare.js'
import ModalEnquire from '../Modals/ModalEnquire.js';
import back from '../../../Assets/Icons/back.png'
import share from '../../../Assets/Icons/share.png'
import Reviews from './Reviews.js'

export default function ArtistProfile() {

    let { id } = useParams()
    let navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [openEnquire, setOpenEnquire] = useState(false);
    const handleOpen = () => setOpenEnquire(true);
    const handleClose = () => setOpen(false);
    const [tab, setTab] = useState(0)
    const [liked, setLiked] = useState(false)
    const [act, setAct] = useState("")

    const textareaRef = useRef(null);

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
    }, [act]);

    useEffect(() => {
        isLiked()
    }, [])

    useEffect(() => {
        getAct()
    },[])

    const getAct = () => {
        axios.post(`${process.env.REACT_APP_API}/acts/get-act-by-id`, {actId:id})
        .then((res) => {
            setAct(res.data)
        })
        .catch((error) => console.log("Error getting acts by id", error))
    }

    const isLiked = () => {
        let likedStatus = localStorage.getItem(`${id}`)
        if (likedStatus == 'true') {
            setLiked(true)
        } else {
            setLiked(false)
        }
    }

    const toggleLike = (value) => {
        setLiked(value)
        localStorage.setItem(`${id}`, value);
    }

    const opts = {
        width: '325',
        height: '225',
        playerVars: {
            controls: 1,
            modestbranding: 1,
            rel: 0,
            fs: 1,
        },
    };


    return (
      <>
          {openShare &&
              <ModalShare
                  setOpen={setOpenShare}
                  artistId={id}
              />
          }
          {openEnquire &&
              <ModalEnquire
                  setOpen={setOpenEnquire}
                  act={act}
              />
          }

          <div className='profile-container'>
            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'baseline', flexWrap: 'wrap'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <button style={{background: 'unset', border: 'unset', padding: 0, marginTop: 0}} onClick={() => navigate('/find/artists')}>
                        <img style={{height: 30, width: 30 , marginRight: 20}} src={back} alt='First Dance Music back button'/>
                    </button>
                    <h1 style={{color: '#ecbdba', fontFamily: 'Mont'}}>{act.actName}</h1>
                </div>
                <div style={{display:'flex'}}>
                    <div className='hover' style={{display:'flex', alignItems: 'baseline', marginRight: 20}} onClick={() => setOpenShare(true)}>
                        <img style={{height: 22, width: 22, marginRight: 10}} src={share} alt='Share First Dance Music artist profile'/>
                        <p>Share</p>
                    </div>

                    <div
                        className='hover'
                        style={{display:'flex', alignItems: 'center'}}
                    >
                        <img onClick={() => toggleLike(!liked)} style={{height: 25, width: 25, marginRight: 10}} src={liked ? likedIcon : likeIcon} alt='Follow First Dance Music artist profile'/>
                        <p>{liked ? "Liked" : "Like"}</p>
                    </div>
                </div>
            </div>

            <div style={{display:'flex', flexWrap: 'wrap'}}>
                <div className='profile-left'>
                    <img className='primary-img' src={act.primaryImg}/>
                    <div className='profile-links'>
                        <div style={{display:'flex', alignItems:'center', flexWrap:'wrap', marginRight: '1rem'}}>
                            <p className='mobile-bio' style={{margin: 0, padding: 0}}>{act.category}</p>
                            <img src={locationIcon} style={{height: 20, width: 20, margin: 5}}/>
                            {act.areas?.map((area, i) => {
                                if (i == (act.areas.length - 1)) {
                                    return <p key={i} style={{margin: 0, padding: 0, fontSize: '0.8rem'}} className='mobile-bio'>{area}</p>
                                } else {
                                    return <p key={i} style={{margin: 0, padding: 0, fontSize: '0.8rem'}} className='mobile-bio'>{area}, &nbsp;</p>
                                }
                            })}
                        </div>

                        <button id='enquire-btn' onClick={handleOpen}>Enquire Now</button>
                    </div>

                    <div className='divider' />
                    <textarea
                        ref={textareaRef}
                        className='profile-bio'
                        value={act.bio ? act.bio : "No Description Available"}
                        readOnly
                    >
                    </textarea>

                    <div className='divider' />
                </div>

                <div className='profile-right'>
                    <div id='profile-tabs'>
                        <h3 className={tab === 0 ? 'profile-tab-active' : 'profile-tab'} onClick={() => setTab(0)}>Videos</h3>
                        <h3 className={tab === 1 ? 'profile-tab-active' : 'profile-tab'} onClick={() => setTab(1)}>Song List</h3>
                        <h3 className={tab === 2 ? 'profile-tab-active' : 'profile-tab'} onClick={() => setTab(2)}>Reviews</h3>
                    </div>

                    {tab === 0 &&
                        <div style={{overflow:'scroll'}}>
                            {act.video && (
                                <div >
                                    <YouTube
                                        videoId={ExtractEmbedFromVideoUrl(act.video)}
                                        opts={opts}
                                        onPlay={(e) => e.target.setPlaybackQuality('small')}
                                    />
                                </div>
                            )}
                        </div>
                    }

                    {tab === 1 &&
                        <div style={{overflow:'scroll'}}>
                            {act.setList?.length > 0 && <p>Here's some of my favourite songs to perform</p>}
                            {act.setList?.length < 1 ?
                                <p>No songs added</p>
                            :
                                act.setList?.map((song,i) => {
                                    return (
                                        <div key={i} style={{display:'flex', alignItems:'center'}}>
                                            <img src={noteIcon} style={{height:20, marginRight: 5}} alt="Note"/>
                                            <p >{song}</p>
                                        </div>
                                    )
                            })}
                        </div>
                    }

                    {tab === 2 &&
                        <Reviews id={id}/>
                    }
                </div>
            </div>
          </div>
      </>
    )
}

