import React, { useState } from 'react'
import './Competition.css'
import compHero from '../../Assets/comp-hero.webp'
import compMobile from '../../Assets/comp-mobile.webp'
import { Link } from 'react-router-dom'
import comp1 from '../../Assets/comp1.webp'
import comp2 from '../../Assets/comp2.webp'
import comp3 from '../../Assets/comp3.webp'
import hearts from '../../Assets/pink-hearts.png'
import play from '../../Assets/play.png'
import { ExtractEmbedFromVideoUrl } from '../../Utilities.js';
import YouTube from 'react-youtube';

export default function Competition() {

    const opts = {
        width: '100%',
        height: '100%',
        playerVars: {
          controls: 1,
          autoplay: 1,
          modestbranding: 1,
          rel: 0,
          fs: 1,
        },
      };

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
      
    const [playing, setPlaying] = useState(false)

    let videoURL = 'https://youtu.be/PUZHHHBs8xo'

  return (
    <div>
        <div id='competition-hero'>
            <div id='comp-hero-left'>
                <h1>Win a Free Live Music Performance for Your Wedding!</h1>
                <p>Planning your wedding just got even more exciting! Post your wedding details on First Dance Music today and get no-obligation quotes from our talented musicians.</p>
                <p>As a bonus, you’ll be entered into a draw for a chance to win a free live performance on your big day!</p>
                <div>
                    <Link 
                        to={'/find'} 
                        className='solid-black-btn'                     
                        style={{fontSize: 20, marginRight: 20, textDecoration:'none'}}
                    >Enter Now</Link>
                    <button className='black-btn' 
                        style={{fontSize: 20}}
                        onClick={() => {
                                handleClickScroll('comp-info')
                            }}
                    >
                            Learn More
                    </button>
                </div>
                
                <img id='hero-mobile' src={compMobile}/>

            </div>

            <div id='comp-hero-right'>
                <img id='comp-hero-desktop' src={compHero} style={{width: '100%'}}/>
            </div>

        </div>
        <div id='comp-info'>
            <div className='comp-info-row'>

                <div className='comp-spacer'/>

                <div className='comp-info-txt'>
                    <h3>How to Enter</h3>
                    <p>Share your wedding details with us by requesting quotes from available artists.</p>
                    <p>That’s it! By doing so, you’re automatically entered into our competition for a chance to win.</p>
                </div>

                <div className='comp-spacer'/>

                <img id='comp-info-hearts' src={hearts} style={{width: '30%', maxWidth: 200}} alt='First Dance Music Free Live Music Competition Hearts'/>

            </div>
            <div className='comp-info-row'>
                <div id='comp-video-container' className='hover' onClick={() => setPlaying(true)}>

                    <img style={playing? {visiblity: 'hidden'} : {}} id='comp-video-img' src={comp1} alt='First Dance Music Wedding Competition Free First Dance or Aisle Walk'/>
                    <img style={playing? {visiblity: 'hidden'} : {}} id='play-comp-btn' src={play} alt='First Dance Music Play Music'/>
                    {playing && <YouTube
                        style={{position:'absolute', top:0, left:0}}
                        className="artist-container-img"
                        videoId={ExtractEmbedFromVideoUrl(videoURL)}
                        opts={opts}
                        onPlay={(e) => e.target.setPlaybackQuality('small')}
                    />}
                </div>

                <div className='comp-spacer'/>

                <div className='comp-info-txt'>
                    <h3>The Prize</h3>
                    <p>One lucky couple will enjoy a free live performance by the incredible Doug Francis, one of our most popular musicians. Doug will perform a song of your choice, either for your romantic aisle walk or your unforgettable first dance at the reception. The music of your dreams, free of charge!</p>
                </div>
            </div>
            <div className='comp-info-row'>
                <div className='comp-spacer'/>
                <img id='comp-img-mobile' className='comp-img' src={comp2} alt='First Dance Music Wedding Competition Free First Dance or Aisle Walk'/>


                <div className='comp-info-txt'>
                    <h3>Why Enter?</h3>
                    <p><span style={{fontWeight: 900}}>No obligation: </span>You’re simply getting quotes from our talented musicians—no strings attached.</p>
                    <p><span style={{fontWeight: 900}}>Easy: </span> It only takes a few minutes to post your wedding details, and you’re in!</p>
                    <p><span style={{fontWeight: 900}}>Free live music: </span>Add an extra touch of magic to your wedding day without the cost.</p>
                </div>

                <div className='comp-spacer'/>

                <img id='comp-img-desktop' className='comp-img' src={comp2} alt='First Dance Music Wedding Competition Free First Dance or Aisle Walk'/>
            </div>
            <div className='comp-info-row'>
                <div className='comp-spacer'/>
                <img className='comp-img' src={comp3} alt='First Dance Music Wedding Competition Free First Dance or Aisle Walk'/>
                <div className='comp-spacer'/>

                <div className='comp-info-txt'>
                    <h3>When is the Winner Announced?</h3>
                    <p>The lucky winner will be announced on our <a href='https://www.facebook.com/wearefirstdance' target='_blank' style={{color:'black', fontWeight: 900}}>Facebook</a> and <a href='https://www.instagram.com/wearefirstdance/' target='_blank' style={{color:'black', fontWeight: 900}}>Instagram</a> pages, so make sure you're following us to stay in the loop! </p>
                    <p>We’ll also notify the winner directly via email.</p>

                    <Link to={'/find'}>
                        <button style={{marginTop: 20}} className='pink-btn'>
                            Enter Now
                        </button>
                    </Link>

                    <h2 style={{fontWeight: 900, fontSize: 40}}>Good Luck!</h2>
                </div>
            </div>
        
        </div>
    </div>
  )
}
