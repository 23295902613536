import React, { useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import { useNavigate, Link } from 'react-router-dom'
import './RequestList.css'
import { useAuth } from '../../Context/AuthContext'
import { ActTypes } from '../../Constants'
import discoBall from '../../Assets/Icons/disco-ball.png'
import filter from '../../Assets/Icons/filter.png'

export default function RequestList() {

    let { currentUser } = useAuth()

    useEffect(() => {
        getRequests()
    }, [])

    const [requests, setRequests] = useState([])
    const [filtered, setFiltered] = useState([])
    const [filterOption, setFilterOption] = useState("All")
    const [showOptions, setShowOptions] = useState(false)

    useEffect(() => {
        if (filterOption === "All") {
            setFiltered(requests)
        } else {
            let filter = requests.filter((request) => filterOption == request.info?.actType)
            setFiltered(filter)
        }

    }, [filterOption])

    const getRequests = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-all-requests-filtered`, {
            artistId: currentUser.uid
        })
        .then((res) => {
            // This eliminates any quotes that are onPause but that an artist hasn't aready engaged with
            let filtered = res.data.filter((request) => {
                // Check if the request is onPause and has not been quoted
                const notQuotedOnPause = (deriveRequestStatus(request) === "Not Quoted" && request.onPause === true)

                // Check if the current user is in the `archived` array with `archiveType` set to "Rejected"
                const userRejected = request.archived.some(archive =>
                    archive.artistId === currentUser.uid && archive.archiveType === "Rejected"
                );

                // Exclude requests that are either not quoted and on pause or rejected by the current user
                return !(notQuotedOnPause || userRejected)
            })
            let sorted = filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setRequests(sorted)
            setFiltered(sorted)
        })
        .catch((error) => console.log(error))
    }

    const deriveRequestStatus = (request) => {
        if (request.quoted.includes(currentUser.uid)) {
            return <p style={{color: '#C1E1C1', fontWeight: 'bold', margin: '5px 0'}}>Quoted</p>
        }  else {
            return <p style={{color: '#ff746c', fontWeight: 'bold', margin: '5px 0'}}>Not Quoted</p>
        }
    }

    const deriveHighlight = (request) => {
        if (request.quoted.includes(currentUser.uid)) {
            return <div className='green-highlight'/>    // Quoted
        }  else {
            return <div className='red-highlight'/>      // Not Quoted
        }
    }

    const handleFilter = (option) => {
        setFilterOption(option)
        setShowOptions(false)
    }

    // TBD
    // Why can I see a request that says paused? Doesn’t paused mean I shouldn’t be able to see it so I can’t quote
    // Or is it because I have quoted. Should this affect my ability to change it?
    return (
        <div  id='request-list-page'>

            <div className='hover' id='request-filter-icon'>
                <img
                    style={{height: 30, width: 30}}
                    src={filter}
                    alt='First Dance Music Wedding Filter'
                    onClick={() => setShowOptions(!showOptions)}
                />
                {showOptions &&
                    <div id='filter-option-popover'>
                        <p className='filter-list-option'onClick={() => handleFilter("All")}>View All</p>
                        {ActTypes.map((act, i) => {
                            return (
                                <p className='filter-list-option' onClick={() => handleFilter(act.name)} key={i}>{act.name}</p>
                                )
                        }
                        )}
                    </div>
                }
            </div>

            <div id='request-list-container'>
                {filtered.length < 1 ? (
                    <p style={{textAlign:'center', paddingTop: 50}}>
                        No requests available
                    </p>
                ) : (
                    filtered
                        .map((request, i) => {

                            let icon = ActTypes.find((act) => act.name == request.info?.actType)

                            if (request.fulfilled !== true) {
                                return (
                                    <Link to={`/artists/requests/${request._id}`} key={i} className='artist-request-container' style={request.onPause ? {backgroundColor: '#f1f1f1', textDecoration: 'none'} : {textDecoration: 'none'}}>
                                        {deriveHighlight(request)}

                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <h2 style={{fontWeight: 900, marginTop: 0, fontSize: 20}}>Request for {request.info.actType} {request.onPause? "(Paused)" : ""}</h2>
                                            <img style={{width: '15%', marginLeft: 10, objectFit: 'contain'}} src={icon?.icon || discoBall} alt='First Dance Music Wedding Icon'/>
                                        </div>

                                        <div>
                                            <p className='request-info-header'>DATE: <span style={{fontWeight:700, letterSpacing: 0}}>{moment(request.date).format('dddd Do MMMM YYYY')}</span></p>
                                            <p className='request-info-header'>VENUE: <span style={{fontWeight:700, letterSpacing: 0}}>{request.info.venue}</span></p>
                                            <p className='request-info-header'>LOCATION: <span style={{fontWeight:700, letterSpacing: 0}}>{request.info.location}</span></p>

                                        </div>

                                        {deriveRequestStatus(request)}
                                    </Link>
                                );
                            }
                        })
                    )}
            </div>
        </div>
    )
}
