import React, { useState } from 'react'
import './QuoteContainer.css'
import { Link, useNavigate } from 'react-router-dom'

export default function QuoteChatContainer({ quote, i, quoteURL }) {

    const [expanded, setExpanded] = useState(false)

    let navigate = useNavigate()

    const handleNavigate = () => {
        console.log("click")
        navigate(quoteURL)
    }

  return (
    
    <div className='quote-option-container' style={{position:'relative', zIndex: 3}} onClick={ handleNavigate } >
        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '10px 20px 0 20px'}}>
            <p style={{fontWeight: 700, color: '#EBA7A2', textTransform:'uppercase', fontSize: 16, letterSpacing: 2, margin: '10px 0'}}>Option {i+1}</p>
        </div>

        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '0 20px'}}>
            <p style={{fontWeight: 900, color: '#313131', fontSize: 20, margin: 0}}>{quote.quoteName}</p>
            <p style={{fontWeight: 900, color: '#313131', fontSize: 20, margin: 0}}>£{(quote.artistFee* quote.vatRate).toFixed(2)}</p>
        </div>

        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '0 20px 10px 20px'}}>
            <p 
                className='hover' 
                style={{ color: '#EBA7A2', fontSize: 14, textDecoration: 'underline', fontWeight: 700, margin: '10px 0'}}
                onClick={(e) =>{
                    e.stopPropagation()
                    setExpanded(!expanded)
                }}
            >
                See {expanded? 'less' : 'more'}
            </p>

            <Link 
                onClick={(e) => e.stopPropagation()}
                to={`/find/artists/${quote.selectedActId}`} 
                target="_blank" rel="noopener noreferrer"
                style={{ color: '#EBA7A2', fontSize: 14, textDecoration: 'underline', fontWeight: 700, margin: '10px 0'}}
            >
                    View act
            </Link>
            
        </div>

        <div className={expanded ? 'artist-quote-expanded': 'artist-quote-minimised' }>

            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div>
                    <p className='quote-expanded-header'>Act</p>
                    <p>{quote.selectedActName}</p>

                    <div>
                        <p className='quote-expanded-header'>Description</p>
                        <p className='quote-expanded-txt' style={{paddingRight: 10, fontSize: 14, maxHeight: 70, overflow: 'scroll'}}>{quote.description ? quote.description : 'No description added'}</p>
                    </div>
                </div>

                <div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'end'}}>
                        <p className='quote-expanded-header'>Amount</p>
                        <p className='quote-expanded-txt'>£{(quote.artistFee * 1).toFixed(2)}</p>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'end'}}>
                        <p className='quote-expanded-header'>VAT ({quote.vatRate == 1.0 ? '0' : '20'}%)</p>
                        <p className='quote-expanded-txt'>£{ (quote.artistFee * quote.vatRate - quote.artistFee).toFixed(2) }</p>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'end'}}>
                        <p className='quote-expanded-header' style={{fontWeight: 900}}>Total</p>
                        <p className='quote-expanded-txt' style={{fontWeight: 900}}>£{(quote.artistFee * quote.vatRate *1).toFixed(2)}</p>
                    </div>
                </div>
            </div>

            

        </div>
    </div>
  )
}
