import React, { useState } from 'react'
import './Modal.css'
import close from '../../../Assets/Icons/close.png'
import { useAuth } from '../../../Context/AuthContext'
import { CircularProgress } from '@mui/material'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from 'axios'
import { toast } from 'react-toastify'
import { storage } from '../../../Firebase';
import { v4 as uuidv4 } from 'uuid';
import defaultImg from '../../../Assets/Icons/anonymous.png'

export default function AddReview({setOpen, actId}) {

  const [clientName, setClientName] = useState("")
  const [clientImg, setClientImg] = useState("")
  const [review, setReview] = useState("")
  const [date, setDate] = useState("")

  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)

  const { currentUser } = useAuth()

  const handleContainerClick = (e) => {
    e.stopPropagation(); 
  };

  let id = uuidv4()

  const handleSubmit = () => {

    let payload = {
        actId,
        artistId: currentUser.uid,
        clientName,
        clientImg,
        review,
        date,
        verified: false,
    }

    if(!clientName || !review || !date){
        toast.error("Please add required fields")
    } else {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API}/reviews/create-review`, payload)
        .then(() => {
            toast.success("Successfully added")
            setOpen(false)
            setReview("")
            setClientName("")
            setClientImg("")
            setDate(null)
        })
        .catch(() => {
            toast.error("Error adding review")
        })
        .finally(() => {
            setLoading(false)
        })
    }
  }

  const uploadImg = async (file) =>{

    if(file.size > 2097152){
        toast.error("Image must be less than 2mb")
    } else {
        setUploading(true); // Start loading

        try {
            // Send file to backend for compression
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(`${process.env.REACT_APP_API}/acts/compress-img`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob' // Expect a blob response
            });

            // Create a blob from the response
            const compressedFile = new Blob([response.data], { type: 'image/webp' });

            // Proceed with Firebase upload
            const imgRef = ref(storage, `${currentUser.uid}/${id}`);
            await uploadBytes(imgRef, compressedFile);

            // Get the download URL and update state
            const url = await getDownloadURL(imgRef);
            setClientImg(url);

        } catch (error) {
            console.error(error);
            toast.error("Failed to upload image");
        } finally {
            setUploading(false); // End loading
        }

    }
   
}
  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='close-modal-btn hover' src={close} alt='Close First Dance Music modal' onClick={() => setOpen(false)}/>

          <h3 className='modal-header' style={{color: '#313131'}}>Manually Add Review</h3>

          <p>Adding a client image of the couple or bride helps to increase the credibility of the the review</p>
            {uploading ?     
                <div className='client-review-img'>
                    <CircularProgress color="inherit" />
                </div> 
                :
                <img className='client-review-img' src={clientImg ? clientImg : defaultImg} alt='First dance music bride review'/>
            }
            <input style={{margin: '10px auto'}} type='file' accept="image/*" onChange={(e) => uploadImg(e.target.files[0])}/>

            <label className='modal-label'>Wedding date</label>
            <input
                className="single-input"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{margin: '10px 0'}}
            />

            <input
                className="top-input"
                placeholder="Client name(s)"
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
            />

            <textarea
                className="bottom-input"
                placeholder="Review"
                type="text"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                style={{height: 100, resize: 'none', paddingTop: 10}}
            />

          <button className='modal-btn' onClick={handleSubmit} disabled={loading}>
            {loading ? 'Adding...' : "Add"}
          </button>


        </div>

    </div>
  )
}
