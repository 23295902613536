import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import './ActManager.css'
import { storage } from '../../Firebase';
import { useAuth } from '../../Context/AuthContext';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ActTypes, Timings, Locations } from '../../Constants';
import { ExtractEmbedFromVideoUrl } from '../../Utilities.js'
import AddReview from './Modals/AddReview.js';
import moment from 'moment'
import icon from '../../Assets/Icons/anonymous.png'

export default function ActManager() {

    const defaultImage = "https://firebasestorage.googleapis.com/v0/b/first-dance-music.appspot.com/o/artist_default.png?alt=media&token=10b79004-41a3-48a5-93ce-369685535540"

    let { id } = useParams()
    let { currentUser, admin} = useAuth()
    const [artistId, setArtitstId] = useState("")
    const [saving, setSaving] = useState(false)
    const [published, setPublished] = useState(false)
    const [actName, setActName] = useState("")
    const [category, setCategory] = useState("")
    const [services, setServices] = useState([])
    const [primaryImg, setPrimaryImg] = useState(defaultImage)
    const [video, setVideo] = useState("")
    const [audio, setAudio] = useState("")
    const [setList, setSetList] = useState([])
    const [song, setSong] = useState("")
    const [loading, setLoading] = useState(true)
    const [formChanged, setFormChanged] = useState(false);
    const [bio, setBio] = useState("");
    const [areas, setAreas] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // State to track loading status of img upload and compression
    const [openReview, setOpenReview] = useState(false)

    useEffect(() => {
        const getReviews = () => {
            axios.post(`${process.env.REACT_APP_API}/reviews/get-reviews-by-act`, {id})
            .then((res) => {
                console.log(res)
                setReviews(res.data)
            })
            .catch((e) => {
                console.log(e)
            })
        }
           
        const getAct = () => {
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API}/acts/get-act-by-id`, {actId: id})
            .then((res) => {
                setArtitstId(res.data.artistId)
                setPublished(res.data.published)
                setActName(res.data.actName)
                setCategory(res.data.category)
                setServices(res.data.services)
                setPrimaryImg(res.data.primaryImg)
                setVideo(res.data.video)
                setAudio(res.data.audio)
                setSetList(res.data.setList)
                setBio(res.data.bio)
                setAreas(res.data.areas)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
        }

        getAct()
        getReviews()

    }, [id])

    

    const handlePublish = (checked) => {
        if (primaryImg === defaultImage) {
            toast.error("Please provide an image for your act before publishing");
        } else if (formChanged && checked) {
            toast.error(
                <>
                    You have made changes.
                    <br />
                    Please check and save before publishing.
                </>,
                {
                    style: {
                        width: "400px"
                    }
                }
            )
        } else {
            axios.post(`${process.env.REACT_APP_API}/acts/publish-act`, {actId: id, checked})
            .then((res)=>{
                setPublished(checked)
                checked ?
                    toast.success(
                        <>
                            Successfully published!
                            <br />
                            Your act is now visible to the public
                            <br />
                            and available to be booked!
                        </>,
                        {
                            style: {
                                width: "350px"
                            }
                        }
                    ) : toast("Successfully unpublished")
            })
            .catch((e)=>console.log(e))
        }
    }

    // only soundcloud is allowed at this time
    // an actual URL would be of the form https://soundcloud.com/{username}/{track-slug}
    const isValidAudioLink = (audioUrl) => {
        const audioPrefix = 'https://soundcloud.com/';

        if (audioUrl.startsWith(audioPrefix)) {
            return true;
        }

        return false;
    };

    const handleUpdate = () => {
        let payload = {
            primaryImg,
            images: [],
            video,
            audio,
            actName,
            bio,
            setList,
            category,
            services,
            areas,
            subCategory: "",
            published
        }

        if (video && (ExtractEmbedFromVideoUrl(video) === "")) {
            toast.error("Please add a valid YouTube video link");
        } else if (audio && !isValidAudioLink(audio)) {
            toast.error("Please add a valid soundcloud audio link")
        } else if (!actName) {
            toast.error("Please add an act name")
        } else {
            setSaving(true)
            axios.post(`${process.env.REACT_APP_API}/acts/update-act`, {actId: id, payload})
            .then((res) => {
                setFormChanged(false);
                setSaving(false)
                toast.success("Saved")
            })
            .catch((e) => {
                console.log(e);
                setSaving(false)
            })
        }
    }

    const handleFormChange = () => {
        console.log("form changed")
        setFormChanged(true);
    };

    const uploadImg = async (file) =>{

        if(file.size > 2097152){
            toast.error("Image must be less than 2mb")
        } else {
            setIsLoading(true); // Start loading

            try {
                // Send file to backend for compression
                const formData = new FormData();
                formData.append('file', file);
    
                const response = await axios.post(`${process.env.REACT_APP_API}/acts/compress-img`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob' // Expect a blob response
                });
    
                // Create a blob from the response
                const compressedFile = new Blob([response.data], { type: 'image/webp' });
    
                // Proceed with Firebase upload
                const imgRef = ref(storage, `${currentUser.uid}/${id}`);
                await uploadBytes(imgRef, compressedFile);
    
                // Get the download URL and update state
                const url = await getDownloadURL(imgRef);
                setPrimaryImg(url);
                handleFormChange();
    
            } catch (error) {
                console.error(error);
                toast.error("Failed to upload image");
            } finally {
                setIsLoading(false); // End loading
            }

        }
       
    }

    const addSong = () => {
        if (!song) {
            return
        } else if (setList.length == 10) {
            toast.error("Maximum 10 songs")
            setSong("")
        }else if (song.length > 60) {
            toast.error("Maximum character length is 60")
        } else {
            setSetList(prev => [...prev, song])
            setSong("")
            handleFormChange();
        }
    }

    const removeSong = (index) => {
        handleFormChange();
        setSetList(prev => {
            return prev.filter((_, i) => i !== index)
        })
    }

    const handleServiceChange = (val, checked) => {
        if(checked){
            setServices([...services, val])
        } else {
            let filtered = services.filter((service) => service !== val)
            setServices(filtered)
        }
        handleFormChange()
    }

    const handleLocationChange = (inArray, location) => {
        if(inArray){
            let newArray = areas.filter(area => area !== location)
            setAreas(newArray)
        } else {
            setAreas([...areas, location])
        }
        handleFormChange()
    }


    return (
        <div className='business-outlet' id='act-manager-page'>
            {loading ?      
            <CircularProgress color="inherit" />
            :
            (

            (artistId !== currentUser.uid && !admin) ?

            <p>You are not authorised to access this page</p>
             :
            <>
                <div>
                    <h2>{published ? "Published" : "Publish act"}</h2>
                    <Switch checked={published} color="success" onChange={(e) => {handlePublish(e.target.checked)}} />
                </div>

                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>

                    <div id='act-info-container'>
                        {isLoading ?     
                            <div className='act-manager-image'>
                                <CircularProgress color="inherit" />
                            </div> 
                            :
                            <img className='act-manager-image' src={primaryImg} alt='First dance music Act image'/>
                        }
                        <input type='file' accept="image/*" onChange={(e) => uploadImg(e.target.files[0])}/>

                        <label style={{ marginTop: '0.5rem' }}>Act Name</label>
                        <input className='edit-act-input' value={actName} onChange={(e) => { setActName(e.target.value); handleFormChange(); }}/>

                        <label style={{ marginTop: '0.5rem' }}>Short Bio</label>
                        <textarea className='edit-act-bio' style={{resize: 'none'}} value={bio} onChange={(e) => { setBio(e.target.value); handleFormChange(); }} />

                        <label>Category</label>
                        <select
                            className='edit-act-input'
                            value={category}
                            onChange={(e) => { setCategory(e.target.value); handleFormChange(); }}>
                            <option value="">Select</option>
                            {ActTypes.map((type, i) => (
                                <option key={i} value={type.name}>
                                {type.name}
                                </option>
                            ))}
                        </select>
                        <label>
                            Video Link (youtube)
                        </label>
                        <span style={{ fontSize: '0.8rem', display: 'block' }}>
                            (from browser URL or Youtube Share Embed link)
                        </span>
                        <input
                            className='edit-act-input'
                            value={video}
                            onChange={(e) => { setVideo(e.target.value); handleFormChange(); }}
                        />

                        <label>
                            Audio Link (soundcloud)
                        </label>
                        <span style={{ fontSize: '0.8rem', display: 'block' }}>
                            (using Share or Copy Link)
                        </span>
                        <input
                            className='edit-act-input'
                            value={audio}
                            onChange={(e) => { setAudio(e.target.value); handleFormChange();}}
                        />
                    </div>

                    <div id='act-info-right'>
                        <h2 style={{margin: 0}}>Suitable For</h2>
                        <p style={{color: 'grey'}}>This will be used as part of the client filter option</p>

                        <div style={{display:'flex', flexDirection:'column', alignItems:'baseline', marginBottom: 20}}>
                            {Timings.map((service, i) => {

                                let isChecked = services.includes(service.name)

                                return (
                                    <div key={i} style={{marginBottom: 10}}>
                                        <input className='service-checkbox' type='checkbox' checked={isChecked} onChange={(e) => handleServiceChange(service.name, e.target.checked) }/>
                                        <label>{service.name}</label>
                                    </div>
                                )

                            })}
                        </div>

                        

                        <h2 style={{margin: 0}}>Favourite songs to perform</h2>
                        <p style={{color: 'grey'}}>List 10 of your favourite songs to perform</p>
                        {setList.length < 1 ?
                        <p>No songs added</p>
                        :

                            setList.map((song, i) => {
                                return (
                                    <div key={i} className='song-item'>
                                        <p>{song}</p>
                                        <button className='remove-song-btn' onClick={()=> removeSong(i)}>
                                            Remove
                                        </button>
                                    </div>
                                ) 
                            })
                        }

                        <input
                            value={song}
                            className='edit-act-input'
                            style={{width: '100%', marginBottom: 10}}
                            placeholder='eg. Never Gonna Give You Up - Rick Astley'
                            onChange={(e) => setSong(e.target.value)}
                        />
                        <button className='primary-btn' onClick={addSong} >Add +</button>

                        <div>
                            <h2 style={{margin: '20px 0'}}>Locations Covered</h2>

                            {Locations.map((location, i) => {
                                let inArray = areas.includes(location)
                                return (
                                    <div key={i} style={{textAlign:'left', margin: '5px 0'}}>
                                        <label>{location}</label>
                                        <input 
                                            type='checkbox' 
                                            checked={inArray} 
                                            onChange={() => handleLocationChange(inArray, location)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                 

                </div>

                <div style={{margin: '2rem auto'}}>
                    <Link to={`/find/artists/${id}`}>
                        <button className='primary-btn' style={{marginRight: 10}}>
                            Preview Act
                        </button>
                    </Link>
                    
                    <button
                        className='green-btn'
                        onClick={handleUpdate}
                        disabled={!formChanged || saving}
                        style={!formChanged || saving ? {backgroundColor: '#d3d3d3'}: {}
                        }
                    >
                        {saving? "Saving...":"Save"}
                    </button>
                </div>

                <button className='primary-btn' onClick={() => setOpenReview(true)}>Add Review</button>

               {openReview &&  <AddReview setOpen={setOpenReview} actId={id}/>} 

               {reviews.length < 1 ? <p>No reviews added</p>
                :
                reviews.map((review, i) => {
                    return (
                        <div key={i} style={{textAlign:'left', padding: 10, border: '1px solid #313131', margin: '10px auto', borderRadius: 8, maxWidth: 400}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <img 
                                    style={{
                                        height: 40, 
                                        width: 40, 
                                        objectFit:'cover', 
                                        objectPosition:'center',
                                        marginRight: 10
                                    }} 
                                    src={review.clientImg ? review.clientImg : icon}
                                    alt='First Dance Wedding Music Singer and Band Review'
                                />
                                <p style={{fontWeight: 'bold'}}>{review.clientName}</p>
                            </div>
                            <p style={{fontWeight: 'bold', fontSize: 12}}>{moment(review.date).fromNow()}</p>
                            <p>{review.review}</p>
                        </div>
                    )
                })
               }
            </>
            )
            }
        </div>
    )
}
