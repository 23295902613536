import React, { useState } from 'react'
import './Message.css'
import axios from 'axios'

export default function Message() {

    const [header, setHeader] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const sendMsg = (sendTo) => {
        if (!header || !subject || !message){
            alert("Please add all fields")
        } else {
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API}/admin/send-message`, {sendTo, header, subject, message})
            .then(() => {
                console.log("Admin message successfully sent")
                // Clear state
                setHeader("");setSubject("");setMessage("")
                setLoading(false)
            })
            .catch((error) => {
                console.log("Error sending admin broadcase message", error)
                setLoading(false)
            })
        }
    }

    return (
        <div id='message'>
            <input placeholder='Subject' className='msg-input' type='text' value={subject} onChange={(e) => setSubject(e.target.value) }/>
            <input placeholder='Header'  className='msg-input' type='text' value={header} onChange={(e) => setHeader(e.target.value) }/>
            <textarea placeholder='Message'  id='msg-body' value={message}  onChange={(e) => setMessage(e.target.value)} />

            <button className='send-btn' style={loading ? {backgroundColor:'grey'} : null} onClick={() => sendMsg("All")} disabled>
                Send to All
            </button>
            <button className='send-btn' style={loading ? {backgroundColor:'grey'} : null} onClick={() => sendMsg("Artists")} disabled={loading}>
                Send to Artists
            </button>
            <button className='send-btn' style={loading ? {backgroundColor:'grey'} : null} onClick={() => sendMsg("Clients")} disabled>
                Send to Clients
            </button>
        </div>
    )
}
