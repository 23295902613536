import React from 'react'
import './Terms.css'
import Footer from '../Footer'

export default function ArtitsTerms() {
  return (
    <>
    <div className='terms'>
        <h1 style={{fontFamily:'Mont', color: '#1e1e1e'}}>Terms and Conditions for First Dance Music (Artist)</h1>

        <p><b>Last Updated:</b> June 24, 2024</p>

<p>Welcome to First Dance Music. By using our platform, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services. If you do not agree to these terms, you must not use our platform.</p>

<h3 style={{fontFamily:"Mont"}}>1. Definitions</h3>

    <p className='indented'><b>Platform:</b> Refers to First Dance Music, the online marketplace facilitating transactions between musicians and clients.</p>

    <p className='indented'><b>Musician:</b> An individual or group registered on the platform to provide performance services.</p>

    <p className='indented'><b>Client:</b> An individual or entity seeking to hire musicians through the platform.</p>

    <p className='indented'><b>Profile:</b> The account and information provided by a musician on the platform.</p>

    <p className='indented'><b>Quote:</b> The estimated cost provided by a musician for a specific performance.</p>

    <p className='indented'><b>Payment:</b> The transaction processed via Stripe for services booked through the platform.</p>

    <p className='indented'><b>Client Money Bank Account:</b> The account where funds are held until the performance is completed.</p>

<h3 style={{fontFamily:"Mont"}}>2. Platform Usage</h3>

    <h3><b>2.1 Musicians:</b></h3>

        <li className='indented'><b>2.1.1.</b> Musicians must create a profile and provide accurate information, including video and image content showcasing their services.</li>

        <li className='indented'><b>2.1.2.</b> Musicians are responsible for setting their own rates and providing quotes to clients.</li>

        <li className='indented'><b>2.1.3.</b> Musicians must fulfil the performance as agreed in the quote and comply with any additional terms specified in the booking.</li>

    <h3><b>2.2 Clients:</b></h3>

        <li className='indented'><b>2.2.1.</b> Clients can browse musician profiles and request quotes.</li>

        <li className='indented'><b>2.2.2.</b> Upon accepting a quote, clients agree to make the payment via Stripe, which will be held in the client money bank account.</li>

        <li className='indented'><b>2.2.3.</b> Clients must notify any disputes or issues with the performance within 48 hours after the event.</li>


<h3 style={{fontFamily:"Mont"}}>3. Payments and Fees</h3>

    <p className='indented'><b>3.1</b> All payments are processed via Stripe. By using Stripe, clients and musicians agree to Stripe's terms and conditions.</p>

    <p className='indented'><b>3.2</b> The platform will hold the payment in a client money bank account until the performance is completed.</p>

    <p className='indented'><b>3.3</b> First Dance Music charges the client a platform fee. Musicians will be paid the full amount quoted for their performance.</p>

    <p className='indented'><b>3.4</b> Musicians will be paid after the performance has been fulfilled and no disputes have been raised within the specified timeframe.</p>

<h3 style={{fontFamily:"Mont"}}>4. Cancellation Policy</h3>

<p className='indented'><b>4.1 By Clients:</b></p>

    <li className='double-indented'><b>4.1.1.</b> Clients may cancel a booking, but they will be subject to the cancellation terms outlined in the client's T&Cs.</li>

    <li className='double-indented'><b>4.1.2.</b> A percentage of the total fee will be paid to the musician based on the agreed cancellation terms.</li>

<p className='indented'><b>4.2 By Musicians:</b></p>

    <li className='double-indented'><b>4.2.2.</b> Musicians must notify any cancellations as soon as possible. Failure to do so may result in penalties and affect their profile rating.</li>

<h3 style={{fontFamily:"Mont"}}>5. Dispute Resolution</h3>

    <p className='indented'><b>5.1.</b> Any disputes must be reported within 48 hours after the performance.</p>

    <p className='indented'><b>5.2.</b> The platform will mediate disputes between clients and musicians, but the final resolution rests with the involved parties.</p>

    <p className='indented'><b>5.3.</b> If no resolution is achieved, the funds will be held until an agreement is reached or a legal determination is made.</p>

<h3 style={{fontFamily:"Mont"}}>6. Tax Obligations</h3>

    <p className='indented'><b>6.1.</b> Musicians are responsible for their own tax obligations. First Dance Music is not responsible for any tax liabilities incurred by musicians.</p>

    <p className='indented'><b>6.2.</b> Musicians should keep accurate records of their earnings for tax purposes.</p>

<h3 style={{fontFamily:"Mont"}}>7. Use of Content</h3>

    <p className='indented'><b>7.1.</b> By uploading video and image content to the platform, musicians grant First Dance Music a non-exclusive, royalty-free, worldwide, perpetual license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in any and all media or distribution methods for the purposes of marketing and advertising.</p>

    <p className='indented'><b>7.2.</b> Musicians retain ownership of their content and can revoke this license by deleting their content from the platform or terminating their account.</p>

<h3 style={{fontFamily:"Mont"}}>8.  Limitation of Liability</h3>

    <p className='indented'><b>8.1.</b> First Dance Music is a marketplace facilitating transactions between musicians and clients. We are not responsible for the quality, timing, or conduct of the performances.</p>

    <p className='indented'><b>8.2.</b> The platform is not liable for any damages or losses resulting from the use of our services.</p>

<h3 style={{fontFamily:"Mont"}}>9. Account Termination</h3>

    <p className='indented'><b>9.1.</b> First Dance Music reserves the right to remove or terminate a musician's account at any time if we feel the usage does not meet the standards of our community guidelines.</p>

    <p className='indented'><b>9.2.</b> In the event of account termination, any pending payments due to the musician will be processed in accordance with the platform's standard payment procedures.</p>

<h3 style={{fontFamily:"Mont"}}>10. Changes to Terms</h3>

    <p className='indented'><b>10.1.</b> First Dance Music reserves the right to modify these terms at any time. Changes will be communicated to users via email or the platform.</p>

    <p className='indented'><b>10.2.</b> Continued use of the platform constitutes acceptance of the modified terms.</p>

<h3 style={{fontFamily:"Mont"}}>11. Governing Law</h3>

    <p className='indented'>These terms and conditions are governed by and construed in accordance with the laws of England and Wales. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>

<h3 style={{fontFamily:"Mont"}}>12.  Contact Information</h3>

    <p className='indented'>For any questions or concerns regarding these terms, please contact us at: Email: info@firstdancemusic.co.uk</p>


<b>By using the First Dance Music Service, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</b>
</div>

<Footer />
</>
  )
}
