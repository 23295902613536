import React, { useState } from 'react';
import './ToggleSwitch.css'; 

const ToggleSwitch = ({ isOn, setIsOn }) => {
   
    const handleToggle = () => {
        setIsOn(!isOn);
    };

    return (
        <div
            className="toggle-switch"
            onClick={handleToggle}
            style={{
                backgroundColor: isOn ? '#ecbdba' : '#dfdfdf',
                borderRadius: '5px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                width: '60px', // Adjust size as needed
                height: '30px', // Adjust size as needed
                padding: '3px',
                position: 'relative',
                transition: 'background-color 0.3s ease',
            }}
        >
            <div
                className="toggle-knob"
                style={{
                    backgroundColor: '#fff',
                    width: '24px', // Adjust size as needed
                    height: '24px', // Adjust size as needed
                    borderRadius: '5px',
                    position: 'absolute',
                    top: '3px',
                    left: isOn ? 'calc(100% - 27px)' : '3px', // Adjust the position based on the state
                    transition: 'left 0.3s ease',
                }}
            ></div>
        </div>
    );
};

export default ToggleSwitch;
