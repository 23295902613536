import React, { useState } from 'react'
import bin from '../../Assets/Icons/bin.png'
import './QuoteContainer.css'

export default function QuoteContainer({ quote, i, handleDeleteQuote, setQuoteIndex, setEditQuoteOpen, fulfilled }) {

    const [expanded, setExpanded] = useState(false)

    const editAllowed = () => {
        console.log("quote", quote)
        const compositeQuote =
            Number(quote.mileageFee) > 0 ||
            Number(quote.fuelFee) > 0 ||
            Number(quote.foodFee) > 0 ||
            Number(quote.accommodationFee) > 0

        return !fulfilled && !compositeQuote
    }

  return (
    <div className='quote-option-container' key={i}>
        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '10px 20px 0 20px'}}>
            <p style={{fontWeight: 700, color: '#EBA7A2', textTransform:'uppercase', fontSize: 16, letterSpacing: 2, margin: '10px 0'}}>
                Option {i+1} {quote.hasPaid && ' - has been accepted and paid'}
            </p>
            <img
                className='hover'
                style={{width: 15}}
                src={bin}
                alt='First Dance Wedding Music Delete Quote'
                onClick={!fulfilled ? () => handleDeleteQuote(i, quote._id) : null}
            />
        </div>

        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '0 20px'}}>
            <p style={{fontWeight: 900, color: '#313131', fontSize: 20, margin: 0}}>{quote.quoteName}</p>
            <p style={{fontWeight: 900, color: '#313131', fontSize: 20, margin: 0}}>£{(quote.artistFee* quote.vatRate).toFixed(2)}</p>
        </div>

        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', padding: '0 20px 10px 20px'}}>
            <p
                className='hover'
                style={{ color: '#EBA7A2', fontSize: 14, textDecoration: 'underline', fontWeight: 700, margin: '10px 0'}}
                onClick={() => setExpanded(!expanded)}
            >
                See {expanded ? 'less' : 'more'}
            </p>
            <p
                className='hover'
                style={{ color: '#EBA7A2', fontSize: 14, textDecoration: 'underline', fontWeight: 700, margin: '10px 0' }}
                onClick={() => {
                    if (editAllowed()) {
                        setEditQuoteOpen(true)
                        setQuoteIndex(i)
                    }
                }}
             >
                Edit option
            </p>
        </div>

        <div className={expanded ? 'artist-quote-expanded': 'artist-quote-minimised' }>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div>
                    <p className='quote-expanded-header'>Act</p>
                    <p>{quote.selectedActName}</p>

                    <div>
                        <p className='quote-expanded-header'>Description</p>
                        <p className='quote-expanded-txt' style={{paddingRight: 10, fontSize: 14, maxHeight: 70, overflow: 'scroll'}}>{quote.description ? quote.description : 'No description added'}</p>
                    </div>
                </div>

                <div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'end'}}>
                        <p className='quote-expanded-header'>Amount</p>
                        <p className='quote-expanded-txt'>£{(quote.artistFee * 1).toFixed(2)}</p>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'end'}}>
                        <p className='quote-expanded-header'>VAT ({quote.vatRate == 1.0 ? '0' : '20'}%)</p>
                        <p className='quote-expanded-txt'>£{ (quote.artistFee * quote.vatRate - quote.artistFee).toFixed(2) }</p>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'end'}}>
                        <p className='quote-expanded-header' style={{fontWeight: 900}}>Total</p>
                        <p className='quote-expanded-txt' style={{fontWeight: 900}}>£{(quote.artistFee * quote.vatRate *1).toFixed(2)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
