import React, { useRef, useEffect, useState } from "react";
import close from '../../../Assets/Icons/close.png'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { TextField } from '@mui/material';
import axios from 'axios'
import { toast } from 'react-toastify';
import stripeLogo from '../../../Assets/stripe.png'
import { LogToServer } from '../../../Utilities'
import './PaymentModal.css'
import tick from '../../../Assets/Icons/tick.png'

export default function PaymentModal({setOpen, handlePaymentSuccess, request, customerGross, quote}) {

    const handleContainerClick = (e) => {
        e.stopPropagation(); // Prevents click event from bubbling up to the modal div
    };

    const [step, setStep] = useState(1)

    // thresholds for payment plan
    const minMonthThreshold = 3
    const maxMonthThreshold = 24
    const minGrossThreshold = 250

    const stripe = useStripe()
    const elements = useElements()

    const [cardName, setCardName ] = useState(request.name)
    const [cardEmail, setCardEmail ] = useState(request.email)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isProcessingInstalment, setIsProcessingInstalment] = useState(false)

    const [differenceInMonths, setDifferenceInMonths] = useState(0)
    const [numberOfMonthlyInstalments, setNumberOfMonthlyInstalments] = useState(0)
    const [firstInstalment, setFirstInstalment] = useState(0)
    const [monthlyInstalment, setMonthlyInstalment] = useState(0)
    const [monthlyPlatformFee, setMonthlyPlatformFee] = useState(0)
    const [formCalculated, setFormCalculated] = useState(false)
    const [paymentStyle, setPaymentStyle] = useState("")

    // Create a ref for the payment form container div
    const formRef = useRef(null)

    // calculate the form on load
    useEffect(() => {
        calculateMonthlyInstalments()
    }, [])

    // scroll to the payment form element when it's displayed
    useEffect(() => {
        if (formRef.current) {
            formRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            })
        }
    }, [formCalculated])

    const calculateMonthlyInstalments = () => {
        // Calculate the number of instalments based on the number of months between
        // now and request.date. Instalments must be complete one full month before
        // event date. Initial payment is the deposit, instalments start 1 month later
        // and must finish at least 1 month before
        const dateNow = new Date()

        const start = new Date(dateNow);
        const end = new Date(request.date);

        // determine total span of months
        let months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth())

        // compensate for partial month so payments are complete at least 30 days before
        if (end.getDate() < start.getDate()) {
            months--;
        }

        setDifferenceInMonths(months)
        const numberOfMonthlyInstalments = months - 1
        setNumberOfMonthlyInstalments(numberOfMonthlyInstalments)

        // Calculate the instalment amounts
        // note that this rounds to 2 decimal places digits, the total will therefore
        // be up to 0.5p off. Not a big deal for the deposit but for instalments
        // over 24 months that could be 12p, which a customer might complain about
        // Could fix this by x 100 then floor then / 100 and then toFixed so it always
        // produces a round down of that 0.5p
        const calculateFirstInstalment = (customerGross * 0.1).toFixed(2)
        setFirstInstalment(calculateFirstInstalment)

        const balance = customerGross - calculateFirstInstalment

        if (quote.platformFee) {
            // as above, if we round up, we could be taking up to 0.5p too much
            // this could impact the amount of money available for the client
            // but in a free flowing account with money in/money out it should
            // not be a problem
            // Platform fee is a straight 12.5% and we've just taken 10% so there should be no
            // way the this number below can be negative
            const calculateMonthyPlatformFee = ((quote.platformFee - calculateFirstInstalment) / numberOfMonthlyInstalments).toFixed(2)
            setMonthlyPlatformFee(calculateMonthyPlatformFee)
        }

        // TBD What should happen here in fact is that we calculate how much we will be out in the end
        // and adjust the deposit by that amount. Except...it also depends on the application fee percent
        // which is calculated later :(

        const calculateMonthlyInstalment = (balance / numberOfMonthlyInstalments).toFixed(2)
        setMonthlyInstalment(calculateMonthlyInstalment)

        setFormCalculated(true)
    }

    const allowMonthlyInstalments = () => {
        // Many conditions for monthly instalments
        // Some that are essential now, some that ought to come into play as and when things develop
        // Some crucial aspects:
        //  - maintaining as close to certainty that the act will be able to play. This means
        //    limiting how far in the future weddings can be accepted
        //  - reducing the impact of stripe fees. This means limiting how low monthly amounts
        //    repayments can be. Remember for each payment stripe charges a % plus a 20p charge.
        //    For £10, 20p is already up at 0.2% which is already more than the base percentage
        // Other options to allow payment spread with lower amounts
        //  - take deposit now and start monthly payments at a future date, say 6 months before wedding date
        //
        // payment ranges:
        //   - lowest payment: 250
        //   - date now of 05/06/24
        //       - first allowed 05/10/24; not allowed 05/09/24, so deposit now and then 07, 08, 09 and one month, its actually +4
        //       - last allowed 2026-07-04; not allowed 2026/07/05, so deposit now and then 23 months and one month so actually 25 months permitted
        //
        // note
        //  - payments always need to finish one month in advance of wedding date
        //
        // lowest gross and furthest date
        // Initial 10% non-refundable depost now of £25.00
        // Followed by 23 monthly amounts of £9.38
        // Total to pay £250
        //
        //
        // lowest gross and closest date
        // Initial 10% non-refundable depost now of £25.00
        // Followed by 3 monthly amounts of £112.50
        // Total to pay £250
        //
        // Example for £1000 with payment 22 months in future
        // e.g. date now 03/10/24 and wedding date 04/08/24
        // deposit now £125 on 03/10/24
        // First payment 30 days from now gives 02/08/24
        // 21 payments because tht takes us to 02/07/26
        // Dates from now 03/10/24 to wedding on 04/08/26
        // 2nd nov, dec (2), 2025 jan - dec (12), 2026 jan - jul (7)
        // Total to pay is £1125, so balance of £1000 to be split
        // over 21 months giving (1125-112.50)/21 = £48.214 / month
        //    - total they would repay here is £1124.91
        // QUESTION ROUND DOWN SO NEVER OVERCHARGE?
        //   - BUT THEN would not be enough so ARTIST can get their money
        //     unless we also always round down our part, might cover it.
        // We are owed 125 and have been paid 112.5 so 12.5 remains
        // which is 12.5/21 = £0.595 or 59p over 21 payments or only
        // about 1.12% of each month meaning not enough to cover stripe
        // in each month. Check: 59 x 21 + 112.50 = £124.89 (with round downs)
        // Stripe fee on £48.21 would be £0.67 + 20p = £0.89 per payment
        //
        // impact of stripe fees
        //   1.4% on each card payment plus 0.5% scheduled payment plus 20p
        //   1.9% if business or non-uk card plus 0.5% scheduled payment plus 20p
        // We take 10% upfront as initial payment (non refundable deposit)
        // the gross figure is artist fee plus 12.5% (AppCommission = 1.125)
        // this means we also have 2.5% contigency in each additional payment
        //
        // Minimum date results in 4 payments total, 1 deposit now followed
        // by 3 monthly payments which is a span of 3 months, plus 1 further
        // month before wedding date, to give a total of 4
        // Maximum date results in 24 payments total, 1 deposit now followed
        // by 23 monthly payments which is a span of 24 months plus 1 further
        // month before wedding date, to give a total of 25
        //

        return (
            (differenceInMonths > minMonthThreshold) &&
            (differenceInMonths <= maxMonthThreshold) &&
            (customerGross >= minGrossThreshold)
        )
    }

    const handlePaymentSubmit = (stripeCustomerId, paymentSummary) => {
        // successful payment - return to parent
        const paymentData = {
            message: 'Payment Successfully Submitted',
            stripeCustomerId,
            paymentSummary
        }

        // force the form to be recalculated before display
        setFormCalculated(false)

        // Call the onSuccess function passed from the parent
        handlePaymentSuccess(paymentData);
    };

    const payByInstalments = async () => {
        setIsProcessingInstalment(true);
        console.log("Pay By Instalments")

        try {
            const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: cardEmail,
                },
            });

            // check validity of the card details entered
            if (pmError) {
                setIsProcessingInstalment(false);
                toast.error("There was a problem making the payment. " + pmError.message)
                LogToServer("Error creating payment method", pmError.message)
                return
            }

            if (paymentMethod?.card?.country !== 'GB') {
                LogToServer(`Card is non-UK client ${request.clientId} ${cardEmail}`)
                // TBD consider rejecting non-UK cards as they incur higher fees. see Om for further content
            }

            // create the payment intent for the deposit and then a subscription schedule
            const response = await axios.post(`${process.env.REACT_APP_API}/payments/create-setup-intent-for-schedule`, {
                paymentMethodId: paymentMethod.id,
                deposit: firstInstalment,
                email: cardEmail,
                name: cardName,
                clientId: request.clientId,
                quoteId: quote._id
            })

            const { clientSecret, stripeCustomerId } = response.data;

            const { setupIntent, error: csError } = await stripe.confirmCardSetup(
                clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: cardName,
                        email: cardEmail
                    },
                },
            })

            if (csError) {
                setIsProcessingInstalment(false)
                LogToServer('Error creating payment:', csError.message)
                toast.error("There was a problem making the payment. " + csError.message)
                return
            } else if (!setupIntent) {
                // this shouldn't really happen, if there was no error then we should have the payment intent
                // if we don't then we also should not be carrying forward to create the subscription
                setIsProcessingInstalment(false)
                LogToServer('Problem during payment confirmation. Please try again or contact support')
                toast.error("Problem during payment confirmation. Please try again or contact support")
                return
            }

            // now create the subscription
            const scheduleResponse = await axios.post(`${process.env.REACT_APP_API}/payments/create-instalments-schedule`, {
                paymentMethodId: setupIntent.payment_method, // pass in the method from the confirmation response
                monthlyPlatformFee: quote.platformFee ? monthlyPlatformFee : null,
                deposit: firstInstalment,
                monthlyInstalment,
                numberOfMonthlyInstalments,
                quoteId: quote._id,
                stripeCustomerId,
            })
            let { subscriptionScheduleId, message, paymentSummary } = scheduleResponse.data

            setIsProcessingInstalment(false);
            LogToServer(`Card payment confirmed ${message}`)

            // and we are done with the payment form
            // TBD NEED TO ADD The subscriptionscheulde id into the save
            handlePaymentSubmit(stripeCustomerId, subscriptionScheduleId, paymentSummary)

        } catch (error) {
            // handle specific responses
            if (error.response && error.response.status === 402) {
                // Handle payment failure scenario here
                LogToServer('Payment failed:', error.response.data.error )
                toast.error("There was a problem making the payment. " + error.response.data.error)
            } else {
                LogToServer('Error setting up payment scheme:', error.response?.data?.error || error.message)
                toast.error("Error setting up payment scheme. " + error.response?.data?.error || error.message)
            }
            setIsProcessingInstalment(false);
        }
    }

    const makePayment = async () => {
        setIsProcessing(true);

        try {
            // check validity of the card details entered
            const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
            })

            if (pmError) {
                setIsProcessing(false)
                toast.error(pmError.message)
                LogToServer("Create payment method", pmError.message)
                return;
            }

            if (paymentMethod?.card?.country !== 'GB') {
                LogToServer(`Card is non-UK client ${request.clientId} ${cardEmail}`)
                // TBD consider rejecting non-UK cards as they incur higher fees. see Om for further content
            }

            if (customerGross === 0.3) {
                // ! Payment Test Case !
                LogToServer(`PAYMENT TEST for ${request.clientId}`)
            }

            const response = await axios.post(`${process.env.REACT_APP_API}/payments/create-payment-intent`, {
                customerGross,
                platformFee: quote.platformFee,
                email: cardEmail,
                name: cardName,
                clientId: request.clientId,
                quoteId: quote._id
            });

            const { client_secret, stripeCustomerId, message, paymentSummary } = response.data;

            // confirm the payment using the payment intent secret
            // any of the three elements can be used
            const { paymentIntent, error: cpError  } = await stripe.confirmCardPayment(
                client_secret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: cardName,
                            email: cardEmail
                        },
                    },
                }
            )

            if (cpError) {
                setIsProcessing(false)
                LogToServer('Error creating payment:', cpError.message)
                toast.error("There was a problem making the payment. " + cpError.message)
            } else if (paymentIntent) {
                setIsProcessing(false)
                LogToServer(`Card payment confirmed ${message}`)

                // and we are done with the payment form
                handlePaymentSubmit(stripeCustomerId, paymentSummary)
            } else {
                // this shouldn't really happen, if there was no error then we should have the payment intent
                setIsProcessing(false);
                LogToServer('Potential problem. Please contact support to confirm payment success')
                toast.error("Potential problem. Please contact support to confirm payment success")
            }
        } catch (error) {
            LogToServer('Error creating payment:', error.response?.data?.error || error.message)
            toast.error("There was a problem making the payment. " + error.response?.data?.error || error.message)
            setIsProcessing(false);
        }
    };

    const handlePaymentSelect = (choice) => {
        if (choice === "Installments" ) {
            if (allowMonthlyInstalments()) {
                setPaymentStyle(choice)
            } else {
                setPaymentStyle("")
            }
        } else {
            setPaymentStyle(choice)
        }
    }

// TBD
// Consider that formCalculated was previously used here to ensure the calculations
//  were complete before displaying. Seems to work now without that.

  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
            <img className='close-modal-btn hover' src={close} alt='Close First Dance Music modal' onClick={() => setOpen(false)}/>

            <h3 className='modal-header' style={{color: '#313131'}}>Complete Booking</h3>

            <p>Select between our 0% payment plan or pay in full. Please note, payment plans are only available on bookings above £{minGrossThreshold} and more than {minMonthThreshold} months from today's date.</p>

            {step === 1 && <div className="payment-options">
                <div
                    className={
                        !allowMonthlyInstalments()
                            ? "payment-option-container-disabled"
                            : paymentStyle === "Instalments"
                            ? "payment-option-container-highlighted"
                            : "payment-option-container"
                    }
                    onClick={() => {
                        if (allowMonthlyInstalments()) {
                            handlePaymentSelect("Instalments")
                        }
                    }}
                    style={{ cursor: allowMonthlyInstalments() ? "pointer" : "not-allowed" }}
                >
                    <div className="payment-choice-cirle">
                        {paymentStyle === "Instalments" && <img src={tick} style={{height: 22}}/>}
                    </div>
                    <p style={{fontWeight: 900}}>Pay in instalments:</p>
                    <p className="smallParagraph">
                        Initial 10% <strong>non-refundable</strong> depost now of £{firstInstalment}
                    </p>
                    <p className="smallParagraph">
                        Followed by {numberOfMonthlyInstalments} monthly amounts of £{monthlyInstalment}
                    </p>
                    <p style={{fontWeight: 700}}>Total to pay £{customerGross}</p>
                </div>

                <div className={
                    paymentStyle === "Full"
                        ? "payment-option-container-highlighted"
                        : "payment-option-container"
                    }
                    onClick={() => handlePaymentSelect("Full")}
                >
                    <div className="payment-choice-cirle">
                        {paymentStyle === "Full" && <img src={tick} style={{height: 22}}/>}
                    </div>
                    <p style={{fontWeight: 900}}>
                        Pay in full:
                    </p>
                    <p className="smallParagraph">
                        See Terms and Conditions for details of refunds in the event of cancellation.
                    </p>
                    <p style={{fontWeight: 700}}>
                        Total to pay £{customerGross}
                    </p>
                </div>

                <div style={{display:'flex', justifyContent:'space-between', width: '100%'}}>
                    <div/>
                    <button
                        className="primary-btn"
                        style={!paymentStyle ? {marginTop: 20, backgroundColor:'#dfdfdf'} :{marginTop: 20}}
                        onClick={() => setStep(2)}
                        disabled={!paymentStyle}
                    >
                        Next
                    </button>
                </div>
            </div>}

            {step === 2 &&
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ alignItems: 'center', width: '100%' }}>
                            <h2 style={{ textAlign: 'center' }}>Payment Details</h2>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    sx={{m:'10px', width:'46%'}}
                                    label='Name on card'
                                    value={cardName}
                                    size='small'
                                    onChange={(e) => setCardName(e.target.value)}
                                />
                                <TextField
                                    sx={{m:'5px', width:'46%'}}
                                    disabled
                                    label='Email'
                                    value={cardEmail}
                                    size='small'
                                    onChange={(e) => setCardEmail(e.target.value)}
                                />
                            </div>
                            <div className="card-element-container">
                                <div className="card-element full-width">
                                    <CardNumberElement />
                                </div>
                                <div className="card-element narrow-width">
                                    <CardExpiryElement />
                                </div>
                                <div className="card-element narrow-width">
                                    <CardCvcElement />
                                </div>
                                <div className="stripe-attribution">
                                    <a
                                        href="https://stripe.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    <img src={stripeLogo} alt="Stripe logo"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between', width: '100%'}}>

                        <button className="back-btn" onClick={() => setStep(1)}>Back</button>

                        {paymentStyle === "Instalments" ?
                            <button className="primary-btn"
                                disabled={isProcessingInstalment}
                                onClick={payByInstalments}
                            >
                                {isProcessingInstalment ? "Submitting..." : "Pay by Instalments"}
                            </button>
                            :
                            <button
                                className="primary-btn"
                                disabled={isProcessing}
                                onClick={makePayment}
                            >
                                {isProcessing ? "Submitting..." : "Pay in Full"}
                            </button>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
  )
}
