import React, { useState } from 'react'
import './Modal.css'
import close from '../../../Assets/Icons/close.png'

export default function WithdrawFundsModal({setOpen}) {

  const [amount, setAmount] = useState("")
  const [loading, setLoading] = useState(false)

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const handleSubmit = () => {
    //Do we want a withdrawal/payment schema?
    //Will this set up a support ticket but with a category of payment request?
    //How much of the payment process do we want to automate?
    //What external reconciliation processes can we put in place?
  }

  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='close-modal-btn hover' src={close} alt='Close First Dance Music modal' onClick={() => setOpen(false)}/>

          <h3 className='modal-header' style={{color: '#313131'}}>Withdraw Funds</h3>

          <p>Withdrawals can take up to 72 hours to process. If you have not received the funds after this, please get in touch via our support tool.</p>

          <div className="currency-input-container">
            <span className="currency-symbol">£</span>
            <input
                className="withdraw-input"
                placeholder="Amount to withdraw"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          <button className='modal-btn' onClick={handleSubmit} disabled={loading}>
            {loading ? 'Submitting...' : "Submit"}
          </button>



          {/*<p>Don't have an account? <span className='hover' style={{textDecoration: 'underline'}} onClick={handleSwitch}>Create one for free</span></p>*/}

        </div>

    </div>
  )
}
