import React, { useEffect, useState } from 'react'
import moment from 'moment'
import icon from '../../../Assets/Icons/anonymous.png'
import axios from 'axios'

export default function Reviews({ id }) {

    const [reviews, setReviews] = useState([])

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API}/reviews/get-reviews-by-act`, {id})
        .then((res) => {
            console.log(res)
            setReviews(res.data)
        })
        .catch((e) => {
            console.log(e)
        })

    }, [id])

  return (
    <div>
        {reviews.length < 1 ?
            <p>No reviews added</p>
            :
            reviews.map((review, i) => {
                return (
                    <div key={i}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <img 
                                style={{
                                    height: 40, 
                                    width: 40, 
                                    objectFit:'cover', 
                                    objectPosition:'center',
                                    marginRight: 10
                                }} 
                                src={review.clientImg ? review.clientImg : icon}
                                alt='First Dance Wedding Music Singer and Band Review'
                            />
                            <p style={{fontWeight: 'bold'}}>{review.clientName}</p>
                        </div>
                        <p style={{fontWeight: 'bold', fontSize: 12}}>{moment(review.date).fromNow()}</p>
                        <p>{review.review}</p>
                    </div>
                )
            })
        }
    </div>
  )
}
